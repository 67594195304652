import { createContext, useEffect, useReducer } from 'react';
import LoadingScreen from 'components/LoadingScreen';
import axios from '../utils/axios';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isLoading: false
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;
//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      return {
        isInitialized: true,
        user: action.payload.user,
        isLoading: action.payload.isLoading,
        isAuthenticated: action.payload.isAuthenticated
      };
    }

    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isLoading: false
      };
    }

    case 'LOGOUT': {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  logout: () => {},
  // eslint-disable-next-line no-unused-vars
  login: (email, password, role) => Promise.resolve()
});

// eslint-disable-next-line react/prop-types
export const JWTAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password, role) => {
    const { data } = await axios.post('/auth', {
      email,
      password,
      role
    });
    setSession(data.token);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: data.user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT'
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          console.log('JWT Auth useEffect!');
          dispatch({
            type: 'INIT',
            payload: { user: null, isAuthenticated: false, isLoading: true }
          });
          setSession(accessToken);
          const { data } = await axios.get('/secured/users/me');
          dispatch({
            type: 'INIT',
            payload: { user: data, isAuthenticated: true, isLoading: false }
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: { user: null, isAuthenticated: false, isLoading: false }
          });
          if (window.location.pathname.indexOf('login') < 0) {
            window.location.href = '/login';
            setSession(null);
          }
        }
      } catch (err) {
        logout();
        dispatch({
          type: 'INIT',
          payload: {
            user: null,
            isAuthenticated: false,
            isLoading: false
          }
        });
      }
    })();
  }, []); // show loading until not initialized

  if (state.isLoading) <LoadingScreen />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
