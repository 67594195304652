import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  loading: false,
  list: {
    records: [],
    currentPage: 1,
    searchText: '',
    totalRecords: 0,
    totalRecordsQueried: 0,
    isLoading: false
  },
  selectedProduct: {
    isLoading: false,
    data: {}
  },
  tempAttributesData: [],
  error: false,
  errorMessage: ''
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateTempAttributesData: (state, action) => {
      state.tempAttributesData = action.payload;
    },
    setSearchTextAndPage: (state, action) => {
      state.list.currentPage = action.payload.currentPage;
      state.list.searchText = action.payload.searchText;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductsAsync.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(getAllProductsAsync.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.records = action.payload.records;
        state.list.totalRecords = action.payload.totalRecords;
        state.list.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllProductsAsync.rejected, (state, action) => {
        state.list.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getProductDetailsAsync.pending, (state) => {
        state.selectedProduct.isLoading = true;
      })
      .addCase(getProductDetailsAsync.fulfilled, (state, action) => {
        state.selectedProduct.isLoading = false;
        state.selectedProduct.data = action.payload.data;
      })
      .addCase(getProductDetailsAsync.rejected, (state, action) => {
        state.selectedProduct.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(updateProductDetailsAsync.pending, (state) => {
        state.selectedProduct.isLoading = false;
      })
      .addCase(updateProductDetailsAsync.fulfilled, (state, action) => {
        state.selectedProduct.isLoading = false;
        state.selectedProduct.data = action.payload.data;
      })
      .addCase(updateProductDetailsAsync.rejected, (state, action) => {
        state.selectedProduct.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      });
  }
});

export const { updateTempAttributesData, setSearchTextAndPage } = productsSlice.actions;

export const getAllProductsAsync = createAsyncThunk('getAllProductsAsync', async (args) => {
  const response = await axiosInstance.get('api/products/all' + args.queryString);
  return response.data;
});

export const deleteProductAsync = createAsyncThunk('deleteProductAsync', async (args) => {
  const response = await axiosInstance.delete(`secured/products/${args.id}`);
  return response.data;
});

export const getProductDetailsAsync = createAsyncThunk('getProductDetailsAsync', async (args) => {
  const response = await axiosInstance.get(`api/products/${args.id}`);
  return response.data;
});

export const updateProductDetailsAsync = createAsyncThunk(
  'updateProductDetailsAsync',
  async (args) => {
    const response = await axiosInstance.put(`secured/products/${args.id}`, args.data);
    return response.data;
  }
);

export default productsSlice.reducer;
