import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  loading: false,
  list: {
    records: [],
    currentPage: 1,
    searchText: '',
    totalRecords: 0,
    totalRecordsQueried: 0,
    isLoading: false
  },
  selectedCollection: {
    isLoading: false,
    data: {}
  },
  error: false,
  errorMessage: ''
};

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    resetInitialState: () => initialState,
    resetSelectedCollection: (state) => {
      state.selectedCollection.isLoading = false;
      state.selectedCollection.data = {};
    },
    setSearchTextAndPage: (state, action) => {
      state.list.currentPage = action.payload.currentPage;
      state.list.searchText = action.payload.searchText;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCollectionsAsync.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(getAllCollectionsAsync.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.records = action.payload.records;
        state.list.totalRecords = action.payload.totalRecords;
        state.list.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllCollectionsAsync.rejected, (state, action) => {
        state.list.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getCollectionDetailsAsync.pending, (state) => {
        state.selectedCollection.isLoading = true;
      })
      .addCase(getCollectionDetailsAsync.fulfilled, (state, action) => {
        state.selectedCollection.isLoading = false;
        state.selectedCollection.data = action.payload.data;
      })
      .addCase(getCollectionDetailsAsync.rejected, (state, action) => {
        state.selectedCollection.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(updateCollectionDetailsAsync.pending, (state) => {
        state.selectedCollection.isLoading = false;
      })
      .addCase(updateCollectionDetailsAsync.fulfilled, (state, action) => {
        state.selectedCollection.isLoading = false;
        state.selectedCollection.data = action.payload.data;
      })
      .addCase(updateCollectionDetailsAsync.rejected, (state, action) => {
        state.selectedCollection.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      });
  }
});

export const {
  resetInitialState,
  resetSelectedAttribute,
  setSearchTextAndPage,
  resetGroupAttributes
} = collectionsSlice.actions;

export const getAllCollectionsAsync = createAsyncThunk('getAllCollectionsAsync', async (args) => {
  const response = await axiosInstance.get('secured/collections/all' + args.queryString);
  return response.data;
});

export const getCollectionDetailsAsync = createAsyncThunk(
  'getCollectionDetailsAsync',
  async (args) => {
    const response = await axiosInstance.get(`secured/collections/${args.id}`);
    return response.data;
  }
);

export const createCollectionsAsync = createAsyncThunk('createCollectionsAsync', async (args) => {
  const response = await axiosInstance.post(`secured/collections`, args.data);
  return response.data;
});

export const updateCollectionDetailsAsync = createAsyncThunk(
  'updateCollectionDetailsAsync',
  async (args) => {
    const response = await axiosInstance.put(`secured/collections/${args.id}`, args.data);
    return response.data;
  }
);

export const deleteCollectionAsync = createAsyncThunk('deleteCollectionAsync', async (args) => {
  const response = await axiosInstance.delete(`secured/collections/${args.id}`);
  return response.data;
});

export default collectionsSlice.reducer;
