import { configureStore } from '@reduxjs/toolkit';
import attributesReducer from './slices/attributesSlice';
import categoriesReducer from './slices/categoriesSlice';
import productsReducer from './slices/productsSlice';
import collectionsReducer from './slices/collectionsSlice';
import ordersReducer from './slices/ordersSlice';
import customersReducer from './slices/customersSlice';

export const store = configureStore({
  reducer: {
    attributes: attributesReducer,
    categories: categoriesReducer,
    products: productsReducer,
    collections: collectionsReducer,
    orders: ordersReducer,
    customers: customersReducer
  }
});
