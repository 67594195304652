import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  loading: false,
  list: {
    records: [],
    currentPage: 1,
    searchText: '',
    totalRecords: 0,
    totalRecordsQueried: 0,
    isLoading: false
  },
  attributeGroups: {
    records: [],
    totalRecords: 0,
    searchText: '',
    totalRecordsQueried: 0,
    isLoading: false
  },
  selectedAttribute: {
    isLoading: false,
    data: {}
  },
  error: false,
  errorMessage: ''
};

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    resetInitialState: () => initialState,
    resetSelectedAttribute: (state) => {
      state.selectedAttribute.isLoading = false;
      state.selectedAttribute.data = {};
    },
    setSearchTextAndPage: (state, action) => {
      state.list.currentPage = action.payload.currentPage;
      state.list.searchText = action.payload.searchText;
    },
    resetGroupAttributes: (state) => {
      state.attributeGroups.records = [];
      state.attributeGroups.totalRecords = 0;
      state.attributeGroups.searchText = '';
      state.attributeGroups.totalRecordsQueried = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAttributesAsync.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(getAllAttributesAsync.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.records = action.payload.records;
        state.list.totalRecords = action.payload.totalRecords;
        state.list.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllAttributesAsync.rejected, (state, action) => {
        state.list.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getAttributesDetailsAsync.pending, (state) => {
        state.selectedAttribute.isLoading = true;
      })
      .addCase(getAttributesDetailsAsync.fulfilled, (state, action) => {
        state.selectedAttribute.isLoading = false;
        state.selectedAttribute.data = action.payload.data;
      })
      .addCase(getAttributesDetailsAsync.rejected, (state, action) => {
        state.selectedAttribute.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(updateAttributesDetailsAsync.pending, (state) => {
        state.selectedAttribute.isLoading = false;
      })
      .addCase(updateAttributesDetailsAsync.fulfilled, (state, action) => {
        state.selectedAttribute.isLoading = false;
        state.selectedAttribute.data = action.payload.data;
      })
      .addCase(updateAttributesDetailsAsync.rejected, (state, action) => {
        state.selectedAttribute.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getAllAttributeGroupsAsync.pending, (state) => {
        state.attributeGroups.isLoading = true;
      })
      .addCase(getAllAttributeGroupsAsync.fulfilled, (state, action) => {
        state.attributeGroups.isLoading = false;
        state.attributeGroups.records = action.payload.records;
        state.attributeGroups.totalRecords = action.payload.totalRecords;
        state.attributeGroups.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllAttributeGroupsAsync.rejected, (state, action) => {
        state.attributeGroups.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getAllAttributesByAttributeGroupAsync.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(getAllAttributesByAttributeGroupAsync.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.records = action.payload.records;
        state.list.totalRecords = action.payload.totalRecords;
        state.list.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllAttributesByAttributeGroupAsync.rejected, (state, action) => {
        state.list.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      });
  }
});

export const {
  resetInitialState,
  resetSelectedAttribute,
  setSearchTextAndPage,
  resetGroupAttributes
} = attributesSlice.actions;

export const getAllAttributesAsync = createAsyncThunk('getAllAttributesAsync', async (args) => {
  const response = await axiosInstance.get('secured/attributes/all' + args.queryString);
  return response.data;
});

export const getAttributesDetailsAsync = createAsyncThunk(
  'getAttributesDetailsAsync',
  async (args) => {
    const response = await axiosInstance.get(`secured/attributes/${args.id}`);
    return response.data;
  }
);

export const createAttributeAsync = createAsyncThunk('createAttributeAsync', async (args) => {
  const response = await axiosInstance.post(`secured/attributes`, args.data);
  return response.data;
});

export const updateAttributesDetailsAsync = createAsyncThunk(
  'updateAttributesDetailsAsync',
  async (args) => {
    const response = await axiosInstance.put(`secured/attributes/${args.id}`, args.data);
    return response.data;
  }
);

export const deleteAttributeAsync = createAsyncThunk('deleteAttributeAsync', async (args) => {
  const response = await axiosInstance.delete(`secured/attributes/${args.id}`);
  return response.data;
});

export const getAllAttributeGroupsAsync = createAsyncThunk(
  'getAllAttributeGroupsAsync',
  async () => {
    const response = await axiosInstance.get(`secured/attributes/groups`);
    return response.data;
  }
);

export const getAllAttributesByAttributeGroupAsync = createAsyncThunk(
  'getAllAttributesByAttributeGroupAsync',
  async (args) => {
    const response = await axiosInstance.get(`secured/attributes/group/${args.id}`);
    return response.data;
  }
);

export default attributesSlice.reducer;
