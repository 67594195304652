import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  loading: false,
  list: {
    records: [],
    currentPage: 1,
    searchText: '',
    totalRecords: 0,
    totalRecordsQueried: 0,
    isLoading: false
  },
  selectedCustomer: {
    isLoading: false,
    data: {}
  },
  error: false,
  errorMessage: ''
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetInitialState: () => initialState,
    resetSelectedCustomer: (state) => {
      state.selectedCustomer.isLoading = false;
      state.selectedCustomer.data = {};
    },
    setSearchTextAndPage: (state, action) => {
      state.list.currentPage = action.payload.currentPage;
      state.list.searchText = action.payload.searchText;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomersAsync.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(getAllCustomersAsync.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.records = action.payload.records;
        state.list.totalRecords = action.payload.totalRecords;
        state.list.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllCustomersAsync.rejected, (state, action) => {
        state.list.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getCustomerDetailsAsync.pending, (state) => {
        state.selectedCustomer.isLoading = true;
      })
      .addCase(getCustomerDetailsAsync.fulfilled, (state, action) => {
        state.selectedCustomer.isLoading = false;
        state.selectedCustomer.data = action.payload.data;
      })
      .addCase(getCustomerDetailsAsync.rejected, (state, action) => {
        state.selectedCustomer.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      });
  }
});

export const { resetInitialState, resetSelectedCustomer, setSearchTextAndPage } =
  ordersSlice.actions;

export const getAllCustomersAsync = createAsyncThunk('getAllCustomersAsync', async (args) => {
  const response = await axiosInstance.get('api/customers/all' + args.queryString);
  return response.data;
});

export const getCustomerDetailsAsync = createAsyncThunk('getCustomerDetailsAsync', async (args) => {
  const response = await axiosInstance.get(`api/customers/${args.id}`);
  return response.data;
});

export default ordersSlice.reducer;
