import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import InsertLinkTwoToneIcon from '@mui/icons-material/InsertLinkTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import TagTwoToneIcon from '@mui/icons-material/TagTwoTone';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';

export const navigations = [
  {
    type: 'label',
    label: 'Quick Links'
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: HomeTwoToneIcon
  },
  {
    type: 'label',
    label: 'Catalog'
  },
  {
    name: 'Products',
    path: '/products',
    icon: BusinessCenterTwoToneIcon
  },
  {
    name: 'Categories',
    path: '/categories',
    icon: InsertLinkTwoToneIcon
  },
  {
    name: 'Collections',
    path: '/collections',
    icon: LocalOfferTwoToneIcon
  },
  {
    name: 'Attributes',
    path: '/attributes',
    icon: TagTwoToneIcon
  },
  {
    type: 'label',
    label: 'Sale'
  },
  {
    name: 'Orders',
    path: '/orders',
    icon: ViewInArTwoToneIcon
  },
  {
    type: 'label',
    label: 'Customers'
  },
  {
    name: 'Customers',
    icon: PeopleAltTwoToneIcon,
    path: '/customers'
  }
];
