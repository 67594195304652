import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  loading: false,
  list: {
    records: [],
    currentPage: 1,
    searchText: '',
    totalRecords: 0,
    totalRecordsQueried: 0,
    isLoading: false
  },
  selectedCategory: {
    isLoading: false,
    data: {}
  },
  error: false,
  errorMessage: ''
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    resetInitialState: () => initialState,
    resetSelectedCategory: (state) => {
      state.selectedCategory.isLoading = false;
      state.selectedCategory.data = {};
    },
    setSearchTextAndPage: (state, action) => {
      state.list.currentPage = action.payload.currentPage;
      state.list.searchText = action.payload.searchText;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategoriesAsync.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(getAllCategoriesAsync.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.records = action.payload.records;
        state.list.totalRecords = action.payload.totalRecords;
        state.list.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllCategoriesAsync.rejected, (state, action) => {
        state.list.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getCategoriesDetailsAsync.pending, (state) => {
        state.selectedCategory.isLoading = true;
      })
      .addCase(getCategoriesDetailsAsync.fulfilled, (state, action) => {
        state.selectedCategory.isLoading = false;
        state.selectedCategory.data = action.payload.data;
      })
      .addCase(getCategoriesDetailsAsync.rejected, (state, action) => {
        state.selectedCategory.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(updateCategoriesDetailsAsync.pending, (state) => {
        state.selectedCategory.isLoading = true;
      })
      .addCase(updateCategoriesDetailsAsync.fulfilled, (state, action) => {
        state.selectedCategory.isLoading = false;
        state.selectedCategory.data = action.payload.data;
      })
      .addCase(updateCategoriesDetailsAsync.rejected, (state, action) => {
        state.selectedCategory.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      });
  }
});

export const { resetInitialState, resetSelectedCategory, setSearchTextAndPage } =
  categoriesSlice.actions;

export const getAllCategoriesAsync = createAsyncThunk('getAllCategoriesAsync', async (args) => {
  const response = await axiosInstance.get('secured/categories/all' + args.queryString);
  return response.data;
});

export const getCategoriesDetailsAsync = createAsyncThunk(
  'getCategoriesDetailsAsync',
  async (args) => {
    const response = await axiosInstance.get(`secured/categories/${args.id}`);
    return response.data;
  }
);

export const updateCategoriesDetailsAsync = createAsyncThunk(
  'updateCategoriesDetailsAsync',
  async (args) => {
    const response = await axiosInstance.put(`secured/categories/${args.id}`, args.data);
    return response.data;
  }
);

export const deleteCategoriesAsync = createAsyncThunk('deleteCategoriesAsync', async (args) => {
  const response = await axiosInstance.delete(`secured/categories/${args.id}`);
  return response.data;
});

export default categoriesSlice.reducer;
