import { Global, css } from '@emotion/react';

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      body > iframe {
        pointer-events: none;
      }

      .custom-scrollbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
      }

      .custom-scrollbar::-webkit-scrollbar-thumb {
        border-radius: 6px;
        width: 6px;
        height: 6px;
        background-color: #7a7a7a5f;
      }

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        width: 6px;
        height: 6px;
        background-color: #7a7a7a5f;
      }

      /***********custom-alert-popup*******/
      .custom-alert-popup {
        padding: 0 !important;
      }

      .swal2-container {
        z-index: 1300;
      }

      .custom-alert-popup .swal2-header {
        border-radius: 4px;
        background-color: rgba(235, 235, 235, 0.54);
        color: #535353 !important;
        padding: 1em 1.5em;
      }

      .custom-alert-popup .swal2-html-container {
        padding: 1em 0;
        color: #787878;
        line-height: 1.5rem;
        font-weight: 400;
        text-align: left;
      }

      .custom-alert-popup .swal2-actions {
        margin: 1.25em 0;
        justify-content: flex-end;
        padding: 0 15px;
      }

      .custom-alert-popup #swal2-content {
        text-align: left;
      }

      .custom-alert-popup .swal2-cancel,
      .custom-alert-popup .swal2-confirm {
        padding: 5px 20px;
      }

      .custom-alert-popup .swal2-close {
        line-height: 1.8;
        color: rgba(0, 0, 0, 0.54);
        right: 6px;
      }

      .custom-alert-popup .swal2-title {
        margin: 0;
        display: flex !important;
        font-size: 1.5rem;
        line-height: 1.25;
        font-weight: 600;
      }
      /*********end-custom-alert-popup*********/

      /*********button-style-alert-popup*********/
      .btn {
        padding: 5px 15px !important;
        user-select: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        border-radius: 8px;
        line-height: 1.75;
        min-width: 64px;
      }
      .btn-cancel {
        border: 1px solid rgba(71, 130, 218, 0.5);
        color: #4782da;
        margin-right: 12px;
        margin-left: 12px;
      }
      .btn-primary {
        background-color: #4782da !important;
        border: 1px solid #4782da;
        text-decoration: none;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        color: #fff;
      }
      .btn-primary:hover {
        background-color: rgb(49, 91, 152);
        border: 1px solid rgb(49, 91, 152);
      }

      .employee-mapping-bg-blue {
        background-color: #edf3fc;
      }

      .swal2-shown {
        height: auto;
      }
      body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
        overflow-y: visible !important;
      }

      .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}
  />
);

export default GlobalStyle;
